<div class="container" #container 
    tabindex="1"
    (keyup.arrowup)="changeScale('plus')" 
    (keyup.arrowdown)="changeScale('moins')"
    (keyup.arrowleft)="changePages('left')"
    (keyup.arrowright)="changePages('right')"
    >
    <div class="page gauche"
        [style.width]="pageMaxWidth +'px'"
        [style.height]="pageMaxHeight+'px'"
         >
        <canvas #canvasLeft
            class="gauche"
            [ngStyle]="{'z-index':canvasLefts[0]?.pageNumber }"
            [ngClass]="{'envers': canvasLefts[0]?.envers, 'animate' : canvasLefts[0]?.animate }">
            </canvas>
        <canvas #canvasLeft
            class="gauche" 
            [ngStyle]="{'z-index':canvasLefts[1]?.pageNumber }"
            [ngClass]="{'envers': canvasLefts[1]?.envers, 'animate' : canvasLefts[1]?.animate}">
        </canvas>
        <canvas #canvasLeft 
            class="gauche" 
            [ngStyle]="{'z-index':canvasLefts[2]?.pageNumber }"
            [ngClass]="{'envers': canvasLefts[2]?.envers, 'animate' : canvasLefts[2]?.animate}">
        </canvas>
        <div #textLeft
            class="textContent"
            >
        </div>
    </div>
    <div class="page droit"
        #pageRight
        [style.width]="pageMaxWidth +'px'"
        [style.height]="pageMaxHeight+'px'" 
        >
        <canvas #canvasRight
            class="droit" 
            [ngStyle]="{'z-index': 10000-canvasRights[0]?.pageNumber }"
            [ngClass]="{'envers': canvasRights[0]?.envers, 'animate' : canvasLefts[0]?.animate}">
        </canvas>
        <canvas #canvasRight
            class="droit" 
            [ngStyle]="{'z-index': 10000-canvasRights[1]?.pageNumber }"
            [ngClass]="{'envers': canvasRights[1]?.envers, 'animate' : canvasLefts[1]?.animate}">
        </canvas>
        <canvas #canvasRight
            class="droit"             
            [ngStyle]="{'z-index': 10000-canvasRights[2]?.pageNumber }"
            [ngClass]="{'envers': canvasRights[2]?.envers, 'animate' : canvasLefts[2]?.animate}">
        </canvas>
        <div #textRight
            class="textContent"
            >
        </div>
    </div>
</div>
<div class="dessous">
    <div class="centrer">
        <button class="tools" (click)="changePages('left')"> &lt; </button>
        <button class="tools" id="zoomplus" (click)="changeScale('plus')">
            +
        </button>
        <button  class="tools" id="zoommoins" (click)="changeScale('moins')">
            -
        </button>
        <button class="tools" (click)="changePages('right')">&gt;</button>
    </div>
</div>


