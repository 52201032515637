import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  pdf ;

  constructor() { }

  ngOnInit(): void {

  }

  handleFileInput(file){
    this.pdf = file;
  }
  

}
