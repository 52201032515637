import { Component, OnInit, ElementRef, ViewChild, ViewChildren, Input, HostListener } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import * as PDFJS from 'pdfjs-dist/es5/build/pdf'
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
PDFJS.verbosity = PDFJS.VerbosityLevel.ERRORS;

function isSSR() {
  return typeof window === 'undefined';
}



@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent {
  currentPage = 1;
  currentPdf : any;
  currentScale = 1;
  pageMaxHeight = 0;
  pageMaxWidth = 0;
  firstPageRender = true;
  subscriptions : Subscription[] = [];

  @ViewChildren('canvasLeft') canvasLeftQuery;
  @ViewChildren('canvasRight')  canvasRightQuery;
  @ViewChild('container') container : ElementRef ;
  @ViewChild('textRight') textRight: ElementRef;
  @ViewChild('textLeft') textLeft: ElementRef;
  canvasLefts : any[] = [];
  canvasRights : any[] = [];
  audio = new Audio();
  
  constructor(private elementRef : ElementRef, private http : HttpClient, private route: ActivatedRoute) {
    if (isSSR()) {
      return;
    }
    this.audio.src = "./assets/page-flip-01a.mp3";
    this.audio.load();
    let pdfWorkerSrc: string;
    if (
      window.hasOwnProperty('pdfWorkerSrc') &&
      typeof (window as any).pdfWorkerSrc === 'string' &&
      (window as any).pdfWorkerSrc
    ) {
      pdfWorkerSrc = (window as any).pdfWorkerSrc;
    } else {
      pdfWorkerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${
        (PDFJS as any).version
      }/es5/build/pdf.worker.js`;
    }
    this.subscriptions.push(this.route.queryParams.subscribe(this.loadPdfFromNetwork.bind(this)));
    (PDFJS as any).GlobalWorkerOptions.workerSrc = pdfWorkerSrc;
   }

  ngAfterViewInit(): void {
    this.canvasLefts = this.canvasLeftQuery.map(a=>a);
    this.canvasRights = this.canvasRightQuery.map(a=>a)
  }

  ngOnDestroy(){
    for(const s of this.subscriptions){
      s.unsubscribe();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyBoard(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight':
        this.changePages('right');
        break;

      case 'ArrowLeft':
        this.changePages('left');
        break;

      case 'ArrowUp':
        this.changeScale('plus');
        break;
      
      case 'ArrowDown':
        this.changeScale('moins');
        break;
    
      default:
        break;
    }
  }

  loadPdfFromNetwork(queryParams){
    if(queryParams.path){
      this.http.get(queryParams.path,{responseType:"arraybuffer"}).subscribe(res=>{
        PDFJS.getDocument(res).promise.then(pdf=>{
          this.currentPdf = pdf;
          this.currentPage = 1;
          this.displayPages();
        })
      })
    }
  }

  displayPage(pageNumber, canvas){
    canvas.pageNumber = pageNumber;
    const isImpair = (pageNumber %2);
    const self = this;
    if(1<=pageNumber && pageNumber<=this.currentPdf.numPages){
      this.currentPdf.getPage(pageNumber).then(page=>{
        const viewport = page.getViewport({scale: self.currentScale });
        const canvasContext = canvas.nativeElement.getContext('2d')
        canvas.nativeElement.hidden = false;
        canvas.nativeElement.width = viewport.width;
        canvas.nativeElement.height = viewport.height;
        if(this.currentPage === pageNumber 
          || (this.currentPage-1 ===pageNumber && this.currentPdf.numPages === pageNumber)){
          this.pageMaxHeight = viewport.height + 50;
          this.pageMaxWidth = viewport.width + 50;
        }
        page.render({viewport, canvasContext, intent : 'print'}).promise.then(()=>{
          if(!isImpair){
            var gradient = canvasContext.createLinearGradient(viewport.width-15,0,viewport.width,0);
            gradient.addColorStop(0,"transparent");
            gradient.addColorStop(1,"LightGray");
            canvasContext.fillStyle = gradient;
            canvasContext.fillRect(0,0,viewport.width,viewport.height); 
          }else{
            var gradient = canvasContext.createLinearGradient(0,0,15,0);
            gradient.addColorStop(0,"LightGray");
            gradient.addColorStop(1,"transparent");
            canvasContext.fillStyle = gradient;
            canvasContext.fillRect(0,0,15,viewport.height);
          }
        })
       });
    }
    else{
      canvas.nativeElement.hidden = true;
    }
  }

  displayText(pageNumber, canvas){
    const isImpair = (pageNumber %2);
    const self = this;
    if(1<=pageNumber && pageNumber<=this.currentPdf.numPages){
      this.currentPdf.getPage(pageNumber).then(page=>{
        const viewport = page.getViewport({scale: self.currentScale });
        page.getTextContent().then(function(textContent){
          let container;
          if(isImpair){
            container = self.textRight.nativeElement
          }
          else{
            container = self.textLeft.nativeElement
          }
          container.style.width = viewport.width + "px";
          container.style.height = viewport.height + "px";
          container.innerText = "";
          PDFJS.renderTextLayer({
            textContent,
            container,
            pageIndex : pageNumber,
            viewport,
            textDivs: []
          });
        })
      })
    }
  }

  displayOneSidePages(side : 'left' | 'right', options = {forceDisplay : false}){
    let currentPage, canvass;
    if(side === 'left'){
      currentPage = this.currentPage -1;
      canvass = this.canvasLefts; 
    }
    else if(side === 'right'){
      currentPage = this.currentPage;
      canvass = this.canvasRights;
    }
    const pagesNumber = [currentPage-2, currentPage, currentPage+2];
    const currentPagesNumber = canvass.map(c=>c.pageNumber).sort((a,b)=>a-b);
    let pagesToDisplay, canvasToModify;
    pagesToDisplay = pagesNumber.filter(a=>!currentPagesNumber.includes(a) || options.forceDisplay);
    canvasToModify = canvass.filter(a=>{
      a.envers = false;
      const included = pagesNumber.includes(a.pageNumber) 
      a.animate = included && !options.forceDisplay;
      if(included &&
         ((a.pageNumber === this.currentPage && a.pageNumber <=this.currentPdf.numPages)
          || (a.pageNumber === this.currentPage - 1 && a.pageNumber === this.currentPdf.numPages)
         )){
          this.pageMaxHeight = a.nativeElement.height + 50;
          this.pageMaxWidth = a.nativeElement.width + 50; 
      }
      return !included || options.forceDisplay;
    });

    for(let i=0; i<pagesToDisplay.length; i++){
      const page = pagesToDisplay[i];
      const canvas = canvasToModify[i];
      this.displayPage(page, canvas)
    }
    let pageFlipIndex ;
    if(side === 'left'){
      pageFlipIndex = 2
    }
    else if(side === 'right'){
      pageFlipIndex = 0
    }
    const canvaToFlip = canvass.find(c=>c.pageNumber === pagesNumber[pageFlipIndex]);
    if(canvaToFlip){
      canvaToFlip.envers = true;
    }
    this.displayText(currentPage, canvass.find(c=> c.pageNumber===currentPage));
  }

  displayPages(options = {forceDisplay : false}){
    this.displayOneSidePages('left', options);
    this.displayOneSidePages('right', options);
  }

  retournePage(side : 'left' | 'right'){
    let canvass;
    if(side === "left"){
      canvass= this.canvasLefts;
    }
    else if(side ==="right"){
      canvass = this.canvasRights
    }
    const sortedCanvass = canvass.sort((a,b)=>a.pageNumber-b.pageNumber)
    sortedCanvass[2].envers = !sortedCanvass[2].envers;
    sortedCanvass[0].envers = !sortedCanvass[0].envers;

  }

  changePages(side : 'left' | 'right'){
    const croissant = (side === "right");
    const newPage = this.currentPage + 4*Number(croissant) -2
    if(1<=newPage && newPage<= this.currentPdf.numPages+1){
      this.currentPage = newPage
      this.displayPages();
      this.audio.play();
    }
  }

  changeScale(side : 'plus' | 'moins'){
    const croissant = (side==='plus')
    const newScale = this.currentScale + 0.4*Number(croissant) - 0.2
    if(newScale>0.2 && newScale<2){
      this.currentScale = newScale;
      this.displayPages({forceDisplay : true})
    }  
  }

}
